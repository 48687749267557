.panel-back.themeColorDARK {
    background: #282828;
    color: #fff;
}

.panel-back.themeColorDARK .react-tel-input input.form-control {
    background-color: transparent;
    color: #fff;
    border-color: #757575;
}

.base-Popper-root.themeColorDARK .notificationsCenter.messagesContainer {
    color: #fff;
    background-color: #565656;
}

.themeColorDARK
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.notificationItem {
    background-color: #666;
    color: #fff;
}

.themeColorDARK
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.notificationItem
    span.MuiButtonBase-root.MuiIconButton-root {
    color: #fff;
}

.themeColorDARK
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.notificationItem
    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    color: #fff !important;
}

.panel-back.themeColorDARK .react-tel-input .flag-dropdown {
    background-color: transparent;
    color: #fff;
    border-color: #757575;
}

.panel-back.themeColorDARK .react-tel-input .selected-flag .arrow {
    border-top: 4px solid #fff;
}

.panel-back.themeColorDARK .react-tel-input .country-list {
    background-color: #3e3e3e;
}

.panel-back.themeColorDARK .react-tel-input .country-list .country:hover {
    background-color: #464646;
}

.panel-back.themeColorDARK .react-tel-input .country-list .country.highlight {
    background-color: #464646;
}

.panel-back.themeColorDARK .react-tel-input .flag-dropdown.open .selected-flag {
    background-color: #757575;
}

.panel-back.themeColorDARK .react-tel-input .selected-flag .arrow.up {
    border-top: 0;
    border-bottom: 4px solid #fff;
}

.panel-back.themeColorDARK .react-tel-input .selected-flag:hover {
    background-color: #757575;
}

.panel-back.themeColorDARK
    button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
    color: #fff !important;
}
.panel-back.themeColorDARK .header {
    background: #282828;
}

.panel-back.themeColorDARK .header-logo {
    background: #282828;
}

.panel-back.themeColorDARK .menu {
    background: #282828;
}

.panel-back.themeColorWHITE
    button.MuiButtonBase-root.MuiIconButton-root.bracelet {
    color: #fff;
}

.panel-back.themeColorWHITE
    button.MuiButtonBase-root.MuiIconButton-root.bracelet.White {
    color: #000;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgb(0 0 0 / 31%), 0px 1px 5px 0px rgb(0 0 0 / 41%);
}

.panel-back.themeColorDARK .sidebar-container {
    background: #282828;
}

.panel-back.themeColorDARK
    p.MuiTypography-root.MuiTypography-body1.MuiTypography-colorTextSecondary {
    color: #c1c1c1;
}

.panel-back.themeColorDARK .MuiChip-label {
    color: #fff;
}

.panel-back.themeColorDARK .react-loading-skeleton {
    --base-color: #6a6a6a;
    --highlight-color: #9c9c9c;
}

.panel-back.themeColorDARK .sidebar-container ul.sidebar-list a li {
    color: #ababab;
}

.panel-back.themeColorDARK .sidebar-container ul.sidebar-list > div > li {
    color: #ababab;
}

.panel-back.themeColorDARK .sidebar-container ul.sidebar-list a.is-active {
    background-color: #393939;
}

.panel-back.themeColorDARK .MuiInputBase-root {
    color: #fff;
}

.panel-back.themeColorDARK .MuiInputBase-root svg {
    color: #fff;
}

.panel-back.themeColorDARK .MuiPaper-root {
    background: #464646;
    color: #fff;
    box-shadow: unset;
}

.panel-back.themeColorDARK .MuiButtonBase-root.MuiChip-root.MuiChip-clickable {
    background: #a4b1ff;
}

.panel-back.themeColorDARK .MuiAccordionSummary-content p {
    color: #a4a7d0;
}

.panel-back.themeColorDARK
    .MuiPaper-root.componentTable
    .MuiPaper-root.MuiAccordion-root {
    background: #4b4b4b;
}

.panel-back.themeColorDARK button.MuiButtonBase-root.MuiIconButton-root {
    color: #fff;
}

.panel-back.themeColorDARK .MuiAccordionSummary-expandIcon {
    color: #fff;
}

.panel-back.themeColorDARK th.MuiTableCell-root {
    background-color: #383838;
    color: #fff;
    border-bottom: 1px solid rgb(77 77 77);
}

.panel-back.themeColorDARK td.MuiTableCell-root {
    color: #fff;
    border-bottom: 1px solid rgb(77 77 77);
}

.panel-back.themeColorDARK .MuiTablePagination-root {
    color: #fff;
}

.panel-back.themeColorDARK .MuiInputBase-root fieldset {
    border-color: #757575;
    color: #fff;
}

.panel-back.themeColorDARK .MuiFormControl-root.MuiTextField-root label {
    color: #fff;
}

.panel-back.themeColorDARK .react-loading-skeleton {
    background-color: rgba(255, 255, 255, 0.13);
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.05),
        rgba(255, 255, 255, 0.05)
    );
}

.panel-back.themeColorDARK {
    background: #282828;
}

.panel-back.themeColorDARK ul.ul-menu {
    background: #383838;
}

.panel-back.themeColorDARK .MuiButton-label {
    color: #fff;
}

.panel-back.themeColorDARK ul.ul-menu li {
    border-color: #525252;
}

.MuiDialog-root.panel-back.themeColorDARK {
    background: transparent;
}

.MuiDialog-root.panel-back.themeColorDARK .booksChips {
    color: #fff;
}

.panel-back.themeColorDARK input.PhoneInputInput {
    background: transparent;
    color: #fff;
    border-color: #757575;
}

.panel-back.themeColorDARK ol.officeGrops {
    background: #757575;
}

.panel-back.themeColorDARK
    button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.single_group_btn {
    color: #fff;
}

.panel-back.themeColorDARK .MuiNativeSelect-select:not([multiple]) option,
.panel-back.themeColorDARK .MuiNativeSelect-select:not([multiple]) optgroup {
    color: #000;
}

.back-container.themeColorDARK {
    background-color: #282828;
}

.back-container.themeColorDARK .main_container {
    background: #464646;
    box-shadow: unset;
}

.back-container.themeColorDARK fieldset {
    border-color: #757575;
    color: #fff;
}

.back-container.themeColorDARK label.MuiFormLabel-root.MuiInputLabel-root {
    color: #fff;
}

.back-container.themeColorDARK button.MuiButtonBase-root.MuiIconButton-root {
    color: #fff;
}

.back-container.themeColorDARK svg.MuiSvgIcon-root.arrowBack {
    color: #fff;
}

.back-container.themeColorDARK
    .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #757575;
}

.MuiDialog-root.panel-back.themeColorWHITE {
    background: transparent;
}

.back-container.themeColorDARK input.MuiInputBase-input {
    color: #fff;
}

.back-container.themeColorDARK .MuiInputBase-root.MuiInput-root {
    background-color: #606060;
    color: #fff;
}

.back-container.themeColorDARK .MuiInputBase-root.MuiInput-root svg {
    color: #fff;
}

.back-container.themeColorDARK .language_item > svg {
    color: #fff;
}

.panel-back.themeColorDARK .MuiButton-outlined {
    border-color: rgb(114 114 114);
    color: #fff;
}

.panel-back.themeColorDARK .MuiButton-outlined.choosen_btn {
    background-color: #00bc23 !important;
    color: #fff !important;
}

.panel-back.themeColorDARK .MuiInputBase-root select option {
    color: #000;
}
.panel-back.themeColorDARK a.fc-col-header-cell-cushion {
    color: #fff;
}

.panel-back.themeColorDARK .fc .fc-daygrid-day-number {
    color: #fff;
}

.panel-back.themeColorDARK .fc-view-harness.fc-view-harness-active {
    background-color: #4f4f4f;
}

.panel-back.themeColorDARK .fc td,
.panel-back.themeColorDARK .fc th {
    border-color: #7e7e7e;
}

.panel-back.themeColorDARK .fc-theme-standard .fc-scrollgrid {
    border: 0;
}

.panel-back.themeColorDARK a.fc-col-header-cell-cushion {
    color: #fff;
}

.panel-back.themeColorDARK .fc .fc-daygrid-day-number {
    color: #fff;
}

.panel-back.themeColorDARK .fc-view-harness.fc-view-harness-active {
    background-color: #4f4f4f;
}

.panel-back.themeColorDARK .fc td,
.panel-back.themeColorDARK .fc th {
    border-color: #7e7e7e;
}

.panel-back.themeColorDARK
    button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.red {
    color: #fff !important;
}

.panel-back.themeColorDARK
    button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.yellow {
    color: #fff !important;
}

.panel-back.themeColorDARK .fc-theme-standard .fc-scrollgrid {
    border: 0;
}

.panel-back.themeColorDARK .top_main_block_setting {
    background-color: #474747;
}

.panel-back.themeColorDARK .top_main_block_setting h3 {
    color: #fff;
}

.panel-back.themeColorDARK .top_setting {
    background-color: #8f8f8f;
}

.panel-back.themeColorDARK .top_setting p {
    color: #fff;
}

.panel-back.themeColorDARK .top_main_block_setting select {
    color: #fff !important;
}

.panel-back.themeColorDARK .top_setting.item {
    color: #fff;
}

.panel-back.themeColorDARK
    .MuiPaper-root.MuiAccordion-root.filter_form
    label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    color: #fff;
}

.panel-back.themeColorDARK
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-error
    .MuiOutlinedInput-notchedOutline {
    border-color: #f44336;
}

/* стилів для темної теми випадаючого меню NativeSelect */
/* не знаю зачем это было сделано но то что закоменчено ниже оно ломает весь визуал на темной теме */
.panel-back.themeColorDARK .MuiNativeSelect-select {
    /* background-color: #424242 !important; */ 
    color: #ffffff !important;
}

.panel-back.themeColorDARK .MuiNativeSelect-select option {
    background-color: #424242 !important;
    color: #ffffff !important;
}

.panel-back.themeColorDARK .MuiNativeSelect-select:focus {
    background-color: #616161 !important;
}

.panel-back.themeColorDARK .MuiAccordionSummary-root {
    background-color: #333 !important;
}

.panel-back.themeColorDARK .mobile-statistics-component__mobile-view {
    background-color: #474747 !important;
}

.panel-back.themeColorDARK .support-chat-component__dark-theme-message {
    background-color: #474747 !important;
}

.panel-back.themeColorDARK .paper-component__dark-theme-message {
    background-color: #333 !important;
}
