@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700&display=swap');

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.single_group_btn.MuiButton-colorInherit {
    background-color: unset;
}

input.PhoneInputInput {
    width: 100%;
    padding: 13px;
    box-shadow: unset;
    -webkit-appearance: none;
    outline: none;
    border-image: unset;
    border: 1px solid #0000003d;
    border-radius: 4px;
    font-size: 15px;
    color: #0000009e;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    min-height: 54px;
    padding: 10px 13px;
}

.Toastify__toast-body {
    white-space: pre-line;
}
button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.yellow {
    background-color: #f3ca00 !important;
    color: #000 !important;
}
button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.red {
    background-color: #ff3c3c !important;
    color: #fff !important;
}

button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.red {
    color: #666 !important;
    border: 1px solid #ff3c3c !important;
}

button.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.yellow {
    color: #666 !important;
    border: 1px solid #f3ca00 !important;
}

.error.PhoneInput input.PhoneInputInput {
    border-color: red;
}
.PhoneInput {
    width: 100%;
}

.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop {
    font-size: 13px;
}

/* .panel-back.new-eq {
  background: #abdc9d;
} */

.new_eq_block {
    display: flex;
    flex-direction: column;
}

span.MuiSwitch-root .MuiSwitch-colorPrimary.Mui-checked {
    color: #2ca10a;
}

button.MuiButtonBase-root.MuiIconButton-root.undefinedfalse.ACTIVE_FRANCH {
    background-color: #1aa763;
}

button.MuiButtonBase-root.MuiIconButton-root.undefinedfalse.BLOCK_FRANCH {
    background-color: #d0142c;
}

span.MuiSwitch-root .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #2ca10a;
}

html {
    scroll-behavior: smooth;
}

.toPrewBlockButton {
    display: none;
}

.cityPriceChange {
    margin-top: 25px;
}

.mobile_sort {
    margin: 15px;
    border-radius: 6px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

span.modile_sort_select {
    font-size: 25px;
    width: 35px;
    text-align: center;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile_actions {
    padding: 25px 15px 10px;
    display: flex;
    justify-content: flex-end;
}

.MuiTableCell-root.mobile_actions {
    padding: 25px 15px 10px;
    display: flex;
    justify-content: flex-end;
    border: 0;
}

.main_form {
    width: 100%;
}

.accordionHeadText {
    display: flex;
    text-align: left;
}

.mobile_view input.MuiInputBase-input.MuiInput-input {
    text-align: center;
}

.mobile_view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiPickersToolbarButton-toolbarBtn
    h6.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiTypography-subtitle1 {
    margin: 0 !important;
}

button#cities_report_detailed {
    display: none;
}

span.lang_choose-label {
    text-transform: uppercase;
}

.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    box-shadow: 0 3px 5px 0 #0000001f;
    padding: 0 10px;
}

.MuiDialogContent-root h4 {
    font-size: 16px;
    margin: 18px;
}

.row.item.full
    .MuiPaper-root.MuiAccordion-root.discounts_accordion.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
    background-color: #f3f3f3;
}

.tasks,
.MuiButtonBase-root.MuiTabScrollButton-root.MuiTabs-scrollButtons.MuiTabs-scrollButtonsDesktop {
    color: #000;
}

.tasks .MuiTypography-root.MuiTypography-body1 {
    width: 100%;
}

.MuiDialogContent-root h6 {
    margin-bottom: 30px;
    margin-left: 3px;
}

.AsyncTable-tableWrapper-1 {
    max-height: 50vh;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary {
    color: #000;
}

html,
body {
    margin: 0;
    padding: 0;
    /*font-family: -apple-system, BNavLinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
    /*  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
    /*  sans-serif;*/

    font-family: 'Raleway', sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button.fc-today-button.fc-button.fc-button-primary {
    display: none;
}

.AsyncMobileTable {
    padding: 15px 0;
}

.not_usual_dis {
    margin: 10px 0;
}

.dashboard_stud > div {
    width: 100%;
}

.fc-toolbar h2 {
    text-transform: capitalize;
}

code {
    /*font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",*/
    /*  monospace;*/
}

@keyframes color {
    0% {
        background-color: #ffd30047;
    }
    50% {
        background-color: #ffd30024;
    }
    100% {
        background-color: #ffd30047;
    }
}

tr.MuiTableRow-root.payments_red.MuiTableRow-hover,
.summary_mobile.payments_red {
    background-color: #ff00003b;
}

tr.MuiTableRow-root.payments_yellow.MuiTableRow-hover,
.summary_mobile.payments_yellow {
    background-color: #ffd30047;
    animation-name: color;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

tr.MuiTableRow-root.payments_green.MuiTableRow-hover,
.summary_mobile.payments_green {
    background-color: #91ff5938;
}

td.MuiTableCell-root.MuiTableCell-body.not_disabled {
    cursor: pointer !important;
    opacity: 1 !important;
    pointer-events: unset !important;
}

button.MuiButtonBase-root.MuiIconButton-root.CorrectTime {
    opacity: 1 !important;
    pointer-events: all !important;
    cursor: pointer !important;
}

/* tr.MuiTableRow-root.cant_edit_row.MuiTableRow-hover
    button.MuiButtonBase-root.MuiIconButton-root {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
} */

td.MuiTableCell-root.MuiTableCell-body.not_disabled {
    cursor: pointer !important;
    opacity: 1 !important;
    pointer-events: unset !important;
}
a.fc-event.fc-event-start.fc-event-end.additionalDates {
    background-color: #1486a3;
}

/* 
tr.MuiTableRow-root.cant_edit_row.MuiTableRow-hover
    td.MuiTableCell-root.MuiTableCell-body {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
} */

tr.MuiTableRow-root.sequance_type_mind.MuiTableRow-hover.settingFinished.cant_edit_row,
tr.MuiTableRow-root.sequance_type_eabacus.MuiTableRow-hover.settingFinished.cant_edit_row {
    background-color: #ffff0038;
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
}

.MuiAccordionSummary-root.summary_mobile.cant_edit_row.settingFinished {
    background-color: #ffff0038;
}

.AsyncMobileTable.cant_edit_row.settingFinished .mobile_view {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
}

td.MuiTableCell-root.MuiTableCell-body.Body-actions-45.MuiTableCell-alignRight {
    cursor: not-allowed !important;
    opacity: unset !important;
    pointer-events: unset !important;
}

tr.MuiTableRow-root.sequance_type_mind.MuiTableRow-hover,
.summary_mobile.sequance_type_mind {
    background-color: rgba(145, 255, 89, 0.22);
}

tr.MuiTableRow-root.green.MuiTableRow-hover,
.summary_mobile.green {
    background-color: #59a31421;
}

tr.MuiTableRow-root.cand_dark_yellow.MuiTableRow-hover,
.summary_mobile.cand_dark_yellow {
    background-color: #b19e179c;
}
tr.MuiTableRow-root.act_dark_green.MuiTableRow-hover,
.summary_mobile.act_dark_green {
    background-color: #85bb65;
}

tr.MuiTableRow-root.yellow.MuiTableRow-hover,
.summary_mobile.yellow {
    background-color: #ffff0038;
}

tr.MuiTableRow-root.light-gray.MuiTableRow-hover,
.summary_mobile.light-gray {
    background-color: #00000026;
}

@keyframes newHw {
    0% {
        background-color: #54b5ff1a;
    }
    50% {
        background-color: #54b5ff5e;
    }
    100% {
        background-color: #54b5ff1a;
    }
}

.MuiButtonBase-root.MuiAccordionSummary-root.summary_mobile.newHw,
tr.MuiTableRow-root.newHw.MuiTableRow-hover {
    background-color: #54b5ff1a;
    animation-name: newHw;
    animation-duration: 3s;
    animation-iteration-count: 1;
}

.newHw {
    background-color: #54b5ff1a;
    animation-name: newHw;
    animation-duration: 3s;
    animation-iteration-count: 1;
}

.ArrowBack {
    cursor: pointer;
}
@media screen and (max-width: 768px) {
    .toPrewBlockButton {
        display: flex;
    }
    .CSV_Custom {
        width: 100%;
    }
    .AsyncTable-tableWrapper-29 {
        max-height: unset !important;
    }
    .AsyncTable-tableWrapper-18 {
        max-height: unset !important;
    }
    .AsyncTable-tableWrapper-13 {
        max-height: unset !important;
    }
    .AsyncTable-tableWrapper-67 {
        max-height: unset !important;
    }
    .AsyncTable-tableWrapper-7 {
        max-height: unset !important;
    }
    .AsyncTable-tableWrapper-40 {
        max-height: unset !important;
    }
    .AsyncTable-tableWrapper-12 {
        max-height: unset !important;
    }
    .fc-scroller.fc-day-grid-container {
        height: auto !important;
    }
    .AsyncTable-tableWrapper-1 {
        max-height: unset !important;
    }
    .MuiAccordionActions-root {
        display: block !important;
        text-align: center;
    }
    .MuiAccordionActions-root.MuiAccordionActions-spacing button {
        display: grid;
    }
    .MuiChip-clickable {
        margin-top: 15px !important;
    }
}
.MuiLinearProgress-root {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
}
.dashSection_links {
    width: 23%;
    background-color: #fff;
    text-align: center;
    padding: 4vh 0;
    margin: 10px;
    border-radius: 7px;
}

.dashSection_links h3 {
    margin: 0;
}

section.dashSection {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.panel-back.themeColorDARK .menu svg.MuiSvgIcon-root.MuiSvgIcon-colorAction {
    color: #fff;
}

@media screen and (max-width: 768px) {
    section.dashSection {
        flex-direction: column;
    }
    section.dashSection .MuiPaper-root.MuiPaper-elevation0 {
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 16px;
    }
}

section.dashSection .MuiPaper-root {
    margin-top: 16px;
    margin-right: 16px;
}

a.fc-event.fc-event-start.fc-event-end {
    background-color: #59a314;
    border-radius: 0;
    border: 0;
    height: 12px;
    width: 100%;
    margin: 0;
}

::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #88888869;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #8888889e;
}
/* width */

input#count_book::-webkit-outer-spin-button,
input#count_book::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input#count_book,
input#count_book:hover,
input#count_book:focus {
    appearance: none;
    -moz-appearance: textfield;
}
